.testing {
    color: red;
    font-size: xx-large;
}

.box {
    display: flex;
    justify-content: center;
    padding: 50px;
}

.card {
    width: 100%;
    max-width: 600px;
    margin: 24px;
}

.btn {
    margin-left: 220px;
    border: 0.5px solid black;
}

a.homepage {
    display: block;
    color: black;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;

    &:hover {
        color: black;
    }
}

.footer {
    background: #5b5b5b;
    border-bottom: 5px solid #737373;
    height: 11vh;
    padding: 20px 0 30px;
    color: white;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}
.footerLink {
    color: white;
}

.wrapper {
    background-color: #460073;
    height: 90vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: 
        url('.././modules/assets/imgs/BKG-Lines-Light-White.png');
}
